import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import type { OrderEntity, UpdateOrderDto } from "@rototip/lib-platform/order";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const rfqApi = createApi({
	reducerPath: "api:rfq",
	tagTypes: ["rfq"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/rfq`,
		prepareHeaders,
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getOrCreateRfq: builder.query<OrderEntity, void>({
			query: () => ({
				url: `/`,
				method: "GET",
			}),
			providesTags: ["rfq"],
		}),
		getDraftRfq: builder.query<OrderEntity, [string]>({
			query: (orderId) => ({
				url: `/${orderId}`,
				method: "GET",
			}),
			providesTags: ["rfq"],
		}),
		getDraftRfqWithVersion: builder.query<OrderEntity, [string, string]>({
			query: ([orderId, versionId]) => ({
				url: `/${orderId}/${versionId}`,
				method: "GET",
			}),
			providesTags: ["rfq"],
		}),
		updateRfq: builder.mutation<OrderEntity, [string, UpdateOrderDto]>({
			query: ([orderId, body]) => ({
				url: `/${orderId}`,
				method: "PATCH",
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data: updatedOrder } = await queryFulfilled;
					dispatch(
						rfqApi.util.updateQueryData(
							"getOrCreateRfq",
							undefined,
							() => updatedOrder
						)
					);
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetOrCreateRfqQuery,
	useUpdateRfqMutation,
	useGetDraftRfqQuery,
	useGetDraftRfqWithVersionQuery,
} = rfqApi;

// export endpoints for use in SSR
export const {
	getOrCreateRfq,
	updateRfq,
	getDraftRfq,
	getDraftRfqWithVersion,
} = rfqApi.endpoints;

// export hook to wait for promises resolution
export const getRfqApiPromises = rfqApi.util.getRunningQueriesThunk;
