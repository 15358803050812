import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CurrencyEntity } from "@rototip/lib-platform/order";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const currencyApi = createApi({
	reducerPath: "api:currencies",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/currency`,
		prepareHeaders,
	}),
	tagTypes: ["Currency"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getCurrencies: builder.query<CurrencyEntity[], void>({
			query: () => ({
				url: "",
				method: "GET",
			}),
		}),
	}),
});

export const { useGetCurrenciesQuery } = currencyApi;
