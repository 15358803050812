export const snakeToCamelCase = (str: string) => {
	return str.toLowerCase().replace(/(_\w)/g, (k) => k[1].toUpperCase());
};

export const sanitizeFileName = (
	fileName: string,
	options?: {
		whiteSpacesReplacement?: string;
		nonAlphanumericReplacement?: string;
	}
) => {
	const whiteSpacesRegex = /\s+/g;
	const nonAlphanumericRegex = /[^a-zA-Z0-9_-]/g;
	const whiteSpacesReplacement = options?.whiteSpacesReplacement ?? "-";
	const nonAlphanumericReplacement = options?.nonAlphanumericReplacement ?? "";
	const turkishToEnglish: Record<string, string> = {
		ç: "c",
		ğ: "g",
		ı: "i",
		ö: "o",
		ş: "s",
		ü: "u",
		Ç: "C",
		Ğ: "G",
		İ: "I",
		Ö: "O",
		Ş: "S",
		Ü: "U",
	};

	const parts = fileName.split(".");
	const extension = parts.length > 1 ? parts.pop() : "";
	const name = parts.join(".");

	let sanitizedFileName = name
		.split("")
		.map((char) => turkishToEnglish[char] || char)
		.join("");

	sanitizedFileName = sanitizedFileName
		.replace(whiteSpacesRegex, whiteSpacesReplacement)
		.replace(nonAlphanumericRegex, nonAlphanumericReplacement);
	return extension ? `${sanitizedFileName}.${extension}` : sanitizedFileName;
};
