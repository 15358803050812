import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
	AddressEntity,
	CreateAddressDto,
	UpdateAddressDto,
} from "@rototip/lib-platform/profile/address";
import { HYDRATE } from "next-redux-wrapper";
import { svcProfileApi } from "../userApi/svcProfileApi";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const addressApi = createApi({
	reducerPath: "api:addresses",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/address`,
		prepareHeaders,
	}),
	tagTypes: ["Address", "OrderAddress"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		createAddress: builder.mutation<AddressEntity, CreateAddressDto>({
			// post the data
			query: (data) => {
				const filteredBody = {
					...data,
					state: data.state !== "" && data.state !== " " ? data.state : null,
				};
				return {
					url: "",
					method: "POST",
					body: filteredBody,
				};
			},
			// update the store on post call success
			async onQueryStarted(address, { dispatch, queryFulfilled }) {
				try {
					const { data: createdAddress } = await queryFulfilled;
					dispatch(
						svcProfileApi.util.updateQueryData(
							"getUser",
							undefined,
							(draft) => {
								draft.addresses!.push(createdAddress);
							}
						)
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
		updateAddress: builder.mutation<AddressEntity, [string, UpdateAddressDto]>({
			// patch the data
			query: ([addressId, addressDto]) => {
				const filteredBody = {
					...addressDto,
					state:
						addressDto.state !== "" && addressDto.state !== " "
							? addressDto.state
							: null,
				};
				return {
					url: `/${addressId}`,
					method: "PATCH",
					body: filteredBody,
				};
			},
			// update the store on patch call success
			async onQueryStarted(address, { dispatch, queryFulfilled }) {
				try {
					const { data: updatedAddress } = await queryFulfilled;
					dispatch(
						svcProfileApi.util.updateQueryData(
							"getUser",
							undefined,
							(draft) => {
								draft.addresses = draft.addresses!.filter(
									({ id }: { id: string }) => id !== updatedAddress.id
								);
								draft.addresses.push(updatedAddress);
							}
						)
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const { useUpdateAddressMutation, useCreateAddressMutation } =
	addressApi;

// export endpoints for use in SSR
export const { updateAddress, createAddress } = addressApi.endpoints;
