import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
	CompanyEntity,
	CreateCompanyDto,
	UpdateCompanyDto,
} from "@rototip/lib-platform/profile/company";
import { HYDRATE } from "next-redux-wrapper";
import { svcProfileApi } from "../userApi/svcProfileApi";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const companyApi = createApi({
	reducerPath: "api:companies",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/company`,
		prepareHeaders,
	}),
	tagTypes: ["Company"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		// this data now comes on the user object that we get from profile svc call
		// @todo: delete
		getCompanyByUserId: builder.query<CompanyEntity[], string>({
			query: (userId) => `/user/${userId}`,
			providesTags: ["Company"],
		}),
		createCompany: builder.mutation<CompanyEntity, CreateCompanyDto>({
			// POST the data
			query: (data) => ({
				url: ``,
				method: "POST",
				body: data,
			}),
			// update the store on post call success
			async onQueryStarted(company, { dispatch, queryFulfilled }) {
				try {
					const { data: createdCompany } = await queryFulfilled;
					dispatch(
						svcProfileApi.util.updateQueryData(
							"getUser",
							undefined,
							(draft) => {
								draft.companyId = createdCompany.id;
								draft.company = createdCompany;
							}
						)
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
		updateCompany: builder.mutation<CompanyEntity, [string, UpdateCompanyDto]>({
			// patch the data
			query: ([companyId, companyDto]) => ({
				url: `/${companyId}`,
				method: "PATCH",
				body: companyDto,
			}),
			// update the store on patch call success
			async onQueryStarted(company, { dispatch, queryFulfilled }) {
				try {
					const { data: updatedCompany } = await queryFulfilled;
					dispatch(
						svcProfileApi.util.updateQueryData(
							"getUser",
							undefined,
							(draft) => {
								draft.company = updatedCompany;
							}
						)
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetCompanyByUserIdQuery,
	useCreateCompanyMutation,
	useUpdateCompanyMutation,
} = companyApi;
export const getCompanyApiPromises = companyApi.util.getRunningQueriesThunk;

// export endpoints for use in SSR
export const { getCompanyByUserId, updateCompany, createCompany } =
	companyApi.endpoints;
