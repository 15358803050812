import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { type UpdateEmailPreferencesDto } from "@rototip/lib-platform/profile/email-preferences/dto";
import { type EmailPreferencesEntity } from "@rototip/lib-platform/profile/email-preferences/entities";
import { HYDRATE } from "next-redux-wrapper";
import { svcProfileApi } from "../userApi";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const emailPreferencesApi = createApi({
	reducerPath: "api:email-preferences",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/email-preferences`,
		prepareHeaders,
	}),
	tagTypes: ["EmailPreferences"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		updateEmailPreferences: builder.mutation<
			EmailPreferencesEntity,
			[string, UpdateEmailPreferencesDto]
		>({
			query: ([id, dto]) => ({
				url: `/${id}`,
				method: "PATCH",
				body: dto,
			}),
			async onQueryStarted(userDto, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(svcProfileApi.util.invalidateTags(["ProfileUser"]));
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const { useUpdateEmailPreferencesMutation } = emailPreferencesApi;
export const { updateEmailPreferences } = emailPreferencesApi.endpoints;
