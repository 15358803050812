import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { partApi } from "../partApi";
import { getIsServer, prepareHeaders } from "../utils";

import type { FileUpload } from "@rototip/lib-platform/order";
import type { PartEntity } from "@rototip/lib-platform/order/entities/part";
import type { FileUploadEntity } from "@rototip/lib-platform/order/entities/part-file-upload";
import { uploadFileToS3 } from "../../../lib-ui/src/helpers/s3Uploader";
import { orderApi } from "../orderApi/orderApi";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

/**
 * A proxy to svc-platform part-file-uploads endpoint calls
 * @see: packages/svc-platform/src/part-file-uploads/part-file-uploads.controller.ts
 */
export const partUploadApi = createApi({
	reducerPath: "api:uploads",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/file-uploads`,
		prepareHeaders,
	}),
	tagTypes: ["PartUpload"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		createParts: builder.mutation<
			PartEntity[],
			{
				orderId: string;
				orderVersionId: string;
				fileKeys: string[];
				files: Record<string, File>;
				onRollback?(failedParts: PartEntity[]): Promise<void> | void;
			}
		>({
			query: ({ fileKeys, orderId, orderVersionId }) => ({
				url: `/${orderId}/${orderVersionId}/create-parts`,
				method: "POST",
				body: fileKeys,
			}),
			async onQueryStarted(
				{ orderVersionId, files, onRollback },
				{ dispatch, queryFulfilled }
			) {
				let optimisticParts: PartEntity[] = [];

				const rollBack = async (parts: PartEntity[]) => {
					dispatch(
						partApi.util.updateQueryData(
							"getPartsByOrderVersionId",
							orderVersionId,
							(draft) =>
								draft.filter((part) => !parts.some((p) => p.id === part.id))
						)
					);

					if (onRollback) await onRollback(parts);
				};

				try {
					const { data: createdParts } = await queryFulfilled;

					optimisticParts = [...createdParts];
					dispatch(
						partApi.util.updateQueryData(
							"getPartsByOrderVersionId",
							orderVersionId,
							(draft) => {
								return [...draft, ...optimisticParts];
							}
						)
					);

					const uploadResults = await Promise.allSettled(
						optimisticParts.map(async (part) => {
							const file = part.fileUploads?.at(0);
							if (!file) return { part, success: false };
							try {
								await uploadFileToS3(
									file as FileUploadEntity,
									files[file.originalName]
								);
								return { part, success: true };
							} catch {
								return { part, success: false };
							}
						})
					);

					const rejectedParts = uploadResults
						.filter(
							(result) => result.status === "fulfilled" && !result.value.success
						)
						.map(
							(result) => result.status === "fulfilled" && result.value.part
						) as PartEntity[];

					if (rejectedParts.length) rollBack(rejectedParts);
				} catch {
					rollBack(optimisticParts);
				}
			},
		}),

		uploadSupportingFiles: builder.mutation<
			FileUpload[],
			{
				orderId: string;
				orderVersionId: string;
				partId: string;
				filesMetadata: { name: string; type: string }[];
				files: Record<string, File>;
			}
		>({
			query: ({ filesMetadata, orderId, orderVersionId, partId }) => ({
				url: `/${orderId}/${orderVersionId}/${partId}/supporting-files`,
				method: "POST",
				body: filesMetadata,
			}),
			async onQueryStarted(
				{ orderVersionId, partId, files },
				{ dispatch, queryFulfilled }
			) {
				try {
					const { data: createdFiles } = await queryFulfilled;
					for await (const fileUpload of createdFiles) {
						if (!fileUpload || !files[fileUpload.originalName]) continue;
						await uploadFileToS3(fileUpload, files[fileUpload.originalName]);
					}
					dispatch(
						partApi.util.updateQueryData(
							"getPartsByOrderVersionId",
							orderVersionId,
							(draft) => {
								return [...draft].map((part) => {
									if (part.id !== partId) return part;
									return {
										...part,
										fileUploads: [...part.fileUploads, ...createdFiles],
									};
								});
							}
						)
					);
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),

		deleteSupportingFile: builder.mutation<
			FileUpload,
			{ uploadId: string; orderVersionId: string; partId: string }
		>({
			query: ({ uploadId }) => ({
				url: `/${uploadId}`,
				method: "DELETE",
			}),
			async onQueryStarted(
				{ orderVersionId, partId },
				{ dispatch, queryFulfilled }
			) {
				try {
					const { data: deletedFileUpload } = await queryFulfilled;
					dispatch(
						partApi.util.updateQueryData(
							"getPartsByOrderVersionId",
							orderVersionId,
							(draft) => {
								const part = draft.find(({ id }) => id == partId)!;
								part.fileUploads = part.fileUploads.filter(
									({ id }) => id !== deletedFileUpload.id
								);
							}
						)
					);
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),

		uploadPartThumbnail: builder.mutation<
			FileUpload,
			{
				orderId: string;
				orderVersionId: string;
				uploadId: string;
				file: File;
			}
		>({
			query: ({ orderId, orderVersionId, uploadId }) => ({
				url: `/${orderId}/${orderVersionId}/${uploadId}/thumbnail`,
				method: "GET",
			}),
			async onQueryStarted({ file }, { dispatch, queryFulfilled }) {
				try {
					const { data: fileUpload } = await queryFulfilled;
					if (!fileUpload || !file) throw new Error("File upload not found");
					await uploadFileToS3(fileUpload, file);
					dispatch(orderApi.util.invalidateTags(["order"]));
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),

		updatePartFileUpload: builder.mutation<
			FileUploadEntity,
			{ fileUpload: FileUploadEntity }
		>({
			query: ({ fileUpload }) => ({
				url: `/${fileUpload.id}`,
				method: "PATCH",
				body: fileUpload,
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useCreatePartsMutation,
	useUploadSupportingFilesMutation,
	useDeleteSupportingFileMutation,
	useUploadPartThumbnailMutation,
	useUpdatePartFileUploadMutation,
} = partUploadApi;
