import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { RootState } from "../store";
import { sessionCookiesSelector, userSelector } from "../userSession";
import { getIsServer } from "./isServer";

export const prepareHeaders = (
	headers: Headers,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{ getState }: any
) => {
	if (!getIsServer()) return headers;
	const state = getState() as RootState;
	const encCookies = sessionCookiesSelector(state);
	const user = userSelector(state);
	if (encCookies) {
		try {
			if (!user?.id) throw new Error("User or User ID cannot be undefined");

			const decrypted = AES.decrypt(encCookies, user.id).toString(Utf8);
			const parsed = JSON.parse(decrypted) as Record<string, string>;
			const cookieString = Object.keys(parsed)
				.map((key) => `${key}=${parsed[key]};`)
				.join(" ");
			headers.set("Cookie", cookieString);
		} catch (error) {
			console.error("Decrypting cookies failed:", error);
		}
	}
	return headers;
};
