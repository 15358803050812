import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateUserDto, UserEntity } from "@rototip/lib-platform/user/user";
import { getIsServer, prepareHeaders } from "../utils";

import type { GetUsersEmailsDto } from "@rototip/lib-platform/user/user/dto";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const svcUserApi = createApi({
	reducerPath: "api:users",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/users`,
		prepareHeaders,
	}),
	tagTypes: ["User"],
	endpoints: (builder) => ({
		getUserEmails: builder.query<
			Pick<UserEntity, "id" | "email">[],
			GetUsersEmailsDto
		>({
			query: (body) => ({
				url: "/emails",
				method: "PATCH",
				body,
			}),
		}),
		getAllNonStaffUsers: builder.query<Array<UserEntity>, void>({
			query: () => ({
				url: "/",
				method: "GET",
			}),
			providesTags: ["User"],
		}),
		createUser: builder.mutation<UserEntity, CreateUserDto>({
			query: (dto) => ({
				url: "/create",
				method: "POST",
				body: dto,
			}),
			invalidatesTags: ["User"],
		}),
	}),
});

export const {
	useGetUserEmailsQuery,
	useGetAllNonStaffUsersQuery,
	useCreateUserMutation,
} = svcUserApi;

export const { getUserEmails, getAllNonStaffUsers, createUser } =
	svcUserApi.endpoints;
export const getUserApiPromises = svcUserApi.util.getRunningQueriesThunk;
