import type {
	FileUploadEntity,
	FileUploadProcessingStatus,
} from "@rototip/lib-platform/order";

const updateFileStatus = async (
	file: FileUploadEntity,
	newStatus: FileUploadProcessingStatus
) => {
	try {
		const response = await fetch(
			`/api/platform/file-uploads/status/${file.id}`,
			{
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					originalObjKey: file.originalObjKey,
					thumbnailObjKey: file.thumbnailObjKey,
					uploadStatus: newStatus,
					processingStatus:
						newStatus === "FAILED" ? newStatus : file.processingStatus,
				}),
			}
		);

		if (!response.ok) {
			const errorText = await response.text();
			throw new Error(
				`Failed to update file status: ${response.statusText} - ${errorText}`
			);
		}
	} catch (error) {
		console.error("Error updating file status:", error);
	}
};

const fetchWithRetry = async (
	url: string,
	options: RequestInit,
	retries = 3,
	backoff = 300
): Promise<Response> => {
	for (let i = 0; i <= retries; i++) {
		try {
			const response = await fetch(url, options);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response;
		} catch (error) {
			if (i === retries) {
				throw error;
			}
			console.log("S3 file upload failed, retrying", error);
			await new Promise((resolve) =>
				setTimeout(resolve, backoff * Math.pow(2, i))
			);
		}
	}
	throw new Error("Failed to fetch after retries");
};

export const uploadFileToS3 = async (
	fileUpload: FileUploadEntity,
	fileToUpload: File
): Promise<void> => {
	if (!fileUpload) {
		throw new Error("No file upload info available");
	}

	const { presignedURL, originalName } = fileUpload;

	if (!fileToUpload) {
		throw new Error(`File ${originalName} not found`);
	}

	if (!presignedURL) throw new Error("Missing presigned url");
	try {
		await fetchWithRetry(presignedURL, {
			method: "PUT",
			body: fileToUpload,
		});
		updateFileStatus(fileUpload, "COMPLETED");
	} catch (error) {
		console.error("Error uploading file to S3:", error);
		if (error) updateFileStatus(fileUpload, "FAILED");
	}
};
