import {
	createSelector,
	createSlice,
	type Reducer,
	type Selector,
	type Slice,
} from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { RootState } from "../store";

export type HydrationState = {
	isHydrated: boolean;
	// we need to wait for the second hit of the hydrate action
	// this is relevant only for hydration when navigating
	// https://github.com/kirill-konshin/next-redux-wrapper#app-and-getserversideprops-or-getstaticprops-at-page-level
	hitCount: number;
	isInRouteTransition: boolean;
};

const initialState: HydrationState = {
	isHydrated: false,
	isInRouteTransition: false,
	hitCount: 0,
};

export const hydrationSlice: Slice<HydrationState> = createSlice({
	name: "system:hydration",
	initialState,
	reducers: {
		resetHydrationState: (state) => {
			state.hitCount = 0;
			state.isHydrated = false;
			state.isInRouteTransition = true;
			return state;
		},
		finishRouteTranstition: (state) => {
			state.isInRouteTransition = false;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			(action) => action.type === HYDRATE,
			(state) => {
				state.hitCount += 1;
				if (state.hitCount === 2) state.isHydrated = true;
				return state;
			}
		);
	},
});

export default hydrationSlice.reducer as Reducer<HydrationState>;
export const { resetHydrationState, finishRouteTranstition } =
	hydrationSlice.actions;

// selectors
const selectSelf: Selector<RootState, RootState> = (state: RootState) => state;

export const hydrationStateSelector: Selector<RootState, HydrationState> =
	createSelector(selectSelf, (state) => state[hydrationSlice.name]);

export const shouldSkipSelector: Selector<RootState, boolean> = createSelector(
	hydrationStateSelector,
	(state) => !state.isHydrated && !state.isInRouteTransition
);
