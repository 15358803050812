import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	FinishesAndTolerancesEntity,
	ProductMaterialEntity,
} from "@rototip/lib-platform/order";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

/**
 * A proxy to svc-platform tech-tree endpoint calls
 * @see: packages/svc-platform/src/tech-tree/tech-tree.controller.ts
 */
export const techTreeApi = createApi({
	reducerPath: "api:techTree",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/tech-tree`,
		prepareHeaders,
	}),
	tagTypes: ["ProductMaterial", "FinishesAndTolerances"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getProductMaterialList: builder.query<ProductMaterialEntity[], string>({
			query: () => `/product-material`,
			providesTags: ["ProductMaterial"],
		}),
		getFinishesAndTolerances: builder.query<
			FinishesAndTolerancesEntity,
			{ materialTypeId: string }
		>({
			query: ({ materialTypeId }) =>
				`/finishes-and-tolerances/${materialTypeId}`,
			providesTags: ["FinishesAndTolerances"],
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetProductMaterialListQuery,
	useGetFinishesAndTolerancesQuery,
} = techTreeApi;
