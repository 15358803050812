import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UpdateUserDto, UserEntity } from "@rototip/lib-platform/profile/user";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const svcProfileApi = createApi({
	reducerPath: "api:profile-users",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/users`,
		prepareHeaders,
	}),
	tagTypes: ["ProfileUser"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getUser: builder.query<UserEntity, void>({
			query: () => ({
				url: "/me",
				method: "GET",
			}),
			providesTags: ["ProfileUser"],
		}),
		updateProfileUser: builder.mutation<UserEntity, [string, UpdateUserDto]>({
			query: ([userId, updateUserDto]) => ({
				url: `/${userId}`,
				method: "PATCH",
				body: updateUserDto,
			}),
			async onQueryStarted(userDto, { dispatch, queryFulfilled }) {
				try {
					const { data: updatedUser } = await queryFulfilled;
					dispatch(
						svcProfileApi.util.updateQueryData("getUser", undefined, () => {
							return updatedUser;
						})
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
	}),
});

export const { useGetUserQuery, useUpdateProfileUserMutation } = svcProfileApi;
export const { getUser, updateProfileUser } = svcProfileApi.endpoints;
export const getProfileUserApiPromises =
	svcProfileApi.util.getRunningQueriesThunk;
