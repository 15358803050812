import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const s3ObjectApi = createApi({
	tagTypes: ["file-upload"],
	reducerPath: "api:file-uploads",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/file-uploads`,
		prepareHeaders,
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		generatePresignedUrl: builder.mutation({
			query: ({ fileId }) => ({
				url: `/${fileId}/presigned-url`,
				method: "POST",
			}),
		}),
	}),
});

export const { useGeneratePresignedUrlMutation } = s3ObjectApi;

// export endpoints for use in SSR
export const { generatePresignedUrl } = s3ObjectApi.endpoints;
