import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { orderApi } from "../orderApi";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	CostBreakdownStructureEntity,
	CreateCBSPayloadMessage,
} from "@rototip/lib-platform/order";

const urlPrefix = getIsServer() ? process.env.SVC_PLATFORM_URL : "";

export const partCbsApi = createApi({
	reducerPath: "api:cbs",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/platform/cbs`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		createPartCBS: builder.mutation<
			CostBreakdownStructureEntity,
			CreateCBSPayloadMessage
		>({
			query: (body) => ({
				url: `/`,
				method: "POST",
				body,
			}),
			async onQueryStarted(payload, { dispatch, queryFulfilled }) {
				try {
					void (await queryFulfilled);
					dispatch(orderApi.util.invalidateTags(["order"]));
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),
		getPartCBS: builder.query<CostBreakdownStructureEntity, string>({
			query: (cbsId) => ({
				url: `/${cbsId}`,
				method: "GET",
			}),
		}),
	}),
});

// Export hooks for usage in functional components
export const { useCreatePartCBSMutation, useGetPartCBSQuery } = partCbsApi;

// export endpoints for use in SSR and with form manager
export const { createPartCBS, getPartCBS } = partCbsApi.endpoints;
